$white: #FFFFFF;
$x_light_color: #F5F5FA;
$light_color: #8B8A99;
$medium_color: #706F7F;
$dark_color: #383740;
$x_dark_color: #2D2C33;

// $primary_font: "Lato", sans-serif;
// $secondary_font: "Changa One", sans-serif;

$primary_font: 'Quicksand', sans-serif;
$secondary_font: 'Playfair Display', serif;

.secondary_font {
  font-family: $secondary_font !important;
}

$secondary_font_color: #78787C;
.secondary_font_color {
  color: $secondary_font_color !important;
}

.light_bg {
  background-color: $x_light_color !important;
  color: $x_dark_color !important;
  h1 {
    color: $dark_color !important;
  }
}

.white_font_color {
  color: $white !important;
}

.black_font_color {
  color: #000 !important;
}
